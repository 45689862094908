import Fuse, { IFuseOptions } from 'fuse.js';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export interface FuseOptions<T = any> extends IFuseOptions<T> {
  limit?: number;
  matchAllOnEmptyQuery?: boolean;
}

export const useFuse = <T = string>(list: T[], options: FuseOptions<T>, _query: string = '') => {
  // defining our query state in there directly
  const [query, updateQuery] = useState(_query);

  // removing custom options from Fuse options object
  // NOTE: `limit` is actually a `fuse.search` option, but we merge all options for convenience
  const { limit, matchAllOnEmptyQuery = true, ...fuseOptions } = options;

  // let's memoize the fuse instance for performances
  const fuse = useMemo<Fuse<T>>(() => new Fuse(list, fuseOptions), [list, fuseOptions]);

  // memoize results whenever the query or options change

  const hits = useMemo(
    // if query is empty and `matchAllOnEmptyQuery` is `true` then return all list
    // NOTE: we remap the results to match the return structure of `fuse.search()`
    () => {
      return !query && matchAllOnEmptyQuery
        ? (fuse.getIndex() as unknown as { docs: T[] }).docs.slice(0, limit).map((item, refIndex) => ({ item, refIndex }))
        : fuse.search(query.replace(/[\W_]+/g, ' '), { limit: limit as number });
    },
    [fuse, limit, matchAllOnEmptyQuery, query]
  );

  // debounce updateQuery and rename it `setQuery` so it's transparent
  const setQuery = useCallback(debounce(updateQuery, 10), []);

  // pass a handling helper to speed up implementation
  const onSearch = useCallback((value: string) => setQuery(value), [setQuery]);

  // still returning `setQuery` for custom handler implementations
  return {
    hits,
    onSearch,
    query,
    setQuery
  };
};
