import { conditionalClasses } from '@/utils/tailwind';
import React, { useEffect, useState } from 'react';
import { type FieldError } from 'react-hook-form';
import Datepicker from 'react-tailwindcss-datepicker';
import { v4 as uuidv4 } from 'uuid';
import { FormLabel } from './FormLabel';
interface TextInputProps extends React.ComponentPropsWithoutRef<'input'> {
  label: string;
  error?: string;
  fieldError?: FieldError;
  icon?: React.ReactNode;
  value?: any;
  handleInputChange: (key: string, value: any) => void;
  fieldName?: string | undefined;
  inputClassName?: string;
  minDate?: any;
  maxDate?: any;
  required?: boolean;
  disabled?: boolean;
  startedEditingFlag?: boolean;
}
export const DatePicker = ({
  icon,
  value,
  error,
  fieldError,
  className,
  label,
  handleInputChange,
  fieldName,
  minDate,
  maxDate,
  inputClassName,
  id,
  required,
  disabled,
  startedEditingFlag = false
}: TextInputProps) => {
  const [date, setDate] = useState({
    startDate: null,
    endDate: null
  });
  const [startedEditing, setStartedEditing] = useState(startedEditingFlag);
  const uniqueId = id || uuidv4();
  useEffect(() => {
    setDate({
      startDate: value ? value : null,
      endDate: value ? value : null
    });
  }, [value]);
  useEffect(() => {
    //date field filter (accepts only numbers and '/')
    if (id) {
      // TODO handle the react way
      const elm = document.getElementById(id) as HTMLInputElement;
      elm.addEventListener('keydown', function (e) {
        let value = elm.value;
        let len = value.length;

        // Allow control keys to pass
        if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 37 || e.keyCode === 39) {
          return;
        }

        // Prevent non-numeric keys
        if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
          e.preventDefault();
        }

        // Block '/' unless it's in the correct position
        if (e.key === '/') {
          if (len !== 2 && len !== 5 || value[len - 1] === '/') {
            e.preventDefault();
          }
        }

        // Automatically add '/'
        setTimeout(() => {
          value = elm.value;
          len = value.length;
          if (len === 2 || len === 5) {
            if (value[len - 1] !== '/') {
              elm.value = value + '/';
            }
          }
        }, 0);
      });
    }
  }, []);
  const handleDateChange = (newDate: any) => {
    if (!fieldName) return setDate(newDate);
    handleInputChange(fieldName, newDate.startDate);
  };
  return <div className={conditionalClasses('flex w-full flex-col', className)} data-sentry-component="DatePicker" data-sentry-source-file="DatePicker.tsx">
      <div className="w-full items-center" onClick={() => setStartedEditing(true)}>
        {label && <FormLabel id={id}>
            {label}
            {required ? ' *' : ''}
          </FormLabel>}
        <div className="relative">
          <Datepicker inputClassName={conditionalClasses('w-full rounded-md px-3 py-2 text-gray-900 placeholder-gray-200 focus:bg-white focus:outline-none text-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500 w-full appearance-none rounded-md border bg-white text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-podi-primary sm:text-sm sm:leading-6', {
          'border-red-500 focus:ring-red-500 focus:border-red-500': error && startedEditing,
          'pl-10': icon // Add padding only when icon is present
        }, {
          'focus-ring-0 pointer-events-none bg-gray-50 shadow-none ring-0': disabled
        }, inputClassName)} inputId={id || uniqueId} displayFormat={'MM/DD/YYYY'} minDate={minDate} useRange={false} asSingle={true} maxDate={maxDate} value={date} onChange={event => {
          handleDateChange(event);
          setStartedEditing(true);
        }} disabled={disabled} data-sentry-element="Datepicker" data-sentry-source-file="DatePicker.tsx" />
          {icon && <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{icon}</div>}
        </div>
      </div>
      {startedEditing && error && <p className="p-1 text-sm text-red-500">{error}</p>}
      {fieldError && <p className="p-1 text-sm text-red-500">{fieldError.message}</p>}
    </div>;
};