import { type PurchaseOrderUploadHistoryRejectionType } from '@/schemas/APISchema';
import { ProviderSearchItemAddressSchema, type Provider, type ProviderSearchItem, type ProviderSearchItemAddress, type ProviderSite } from '@/schemas/provider';
import { type PurchaseOrder } from '@/schemas/purchaseOrder';
import { type ProviderLookupOptions } from '@/services/provider';
import { type Dispatch, type SetStateAction } from 'react';

export const handleProviderName = (currentProviderData: Partial<Provider> | Partial<ProviderSearchItem> | undefined | null) => {
  if (!currentProviderData) return '';
  let providerName;
  if (currentProviderData?.name) {
    providerName = currentProviderData.name;
  } else if (currentProviderData?.firstName && currentProviderData?.firstName) {
    providerName = `${currentProviderData?.firstName} ${currentProviderData?.lastName}`;
  } else {
    providerName = '';
  }
  return providerName;
};

export const handlePatientName = (purchaseOrderDetails: Partial<PurchaseOrder> | null | undefined) => {
  if (!purchaseOrderDetails) return '';
  let patientName;
  if (purchaseOrderDetails?.patientName) {
    patientName = purchaseOrderDetails?.patientName;
  } else if (purchaseOrderDetails?.patientFirstName && purchaseOrderDetails?.patientFirstName) {
    patientName = `${purchaseOrderDetails?.patientFirstName} ${purchaseOrderDetails?.patientLastName}`;
  } else {
    patientName = '';
  }
  return patientName;
};

export const handleProviderPhoneNumber = (
  phoneNumbers: {
    phoneNumber: string;
  }[]
) => {
  const foundNumber = phoneNumbers?.find((number) => number?.phoneNumber.length > 9);
  if (foundNumber?.phoneNumber) return foundNumber?.phoneNumber;
  return '';
};

export const handleProviderAddress = (addresses: ProviderSearchItemAddress[]) => {
  const foundAddress = addresses?.find((address) => {
    const parsedAddress = ProviderSearchItemAddressSchema.safeParse(address);
    if (parsedAddress?.success) return parsedAddress?.success;
  });
  if (foundAddress) {
    const { addressLine1, addressLine2, city, state, zip } = foundAddress;
    const streetAddress = addressLine2 ? `${addressLine1} ${addressLine2}` : addressLine1;
    const address = `${streetAddress} ${city} ${state} ${zip}`.toLocaleUpperCase();
    return address;
  }
  return '';
};

export const isProviderInDatabase = (providers: ProviderSearchItem[]) => {
  return providers.some((provider) => !provider.fromRegistry);
};

export const findProviderInDatabase = (providers: ProviderSearchItem[]) => {
  return providers.find((provider) => !provider.fromRegistry);
};

export const findProviderFromRegistry = (providers: ProviderSearchItem[]) => {
  return providers.find((provider) => provider.fromRegistry);
};

export const parseLookupProviders = (
  providers: ProviderSearchItem[],
  search: {
    npi?: string;
    firstName?: string;
    lastName?: string;
    state?: string;
  }
) => {
  const { npi = null, firstName = null, lastName = null, state = null } = search;
  const filteredProviders = providers.find((provider) => {
    if (typeof npi === 'string' && provider?.number.toLocaleLowerCase() !== npi.toLocaleLowerCase()) return false;

    if (typeof firstName === 'string' && provider?.firstName.toLocaleLowerCase() !== firstName.toLocaleLowerCase()) return false;
    if (typeof lastName === 'string' && provider?.lastName.toLocaleLowerCase() !== lastName.toLocaleLowerCase()) return false;

    if (
      typeof state === 'string' &&
      !provider?.addresses?.find((address) => typeof address.state === 'string' && address.state.toLocaleLowerCase() === state.toLocaleLowerCase())
    )
      return false;
    return true;
  });

  return filteredProviders;
};

export const handleBoolData = (val: unknown) => {
  if (typeof val === 'boolean') {
    if (val) return 'Yes';
    return 'No';
  }
  return '';
};

export function handleProviderSiteSelection(providerSiteData: ProviderSite[] | null) {
  if (!providerSiteData || providerSiteData.length < 1) return null;

  for (let i = 0; i < providerSiteData.length; i++) {
    const element = providerSiteData[i];
    if (element) return element;
  }
  return null;
}

export const handleNPIValidation = (npiStr: string, setError: Dispatch<SetStateAction<boolean>>, setErrorMessage: Dispatch<SetStateAction<string>>) => {
  if (npiStr.length !== 10) {
    setError(true);
    setErrorMessage('NPI must be 10 digits long');
    return false;
  }
  if (!npiStr.match(/^[0-9]+$/)) {
    setError(true);
    setErrorMessage('NPI must be only numbers');
    return false;
  }
  setError(false);
  setErrorMessage('');
  return true;
};

export function shallowEqual(ob1Title: string, object1: Record<string, unknown>, ob2Title: string, object2: Record<string, unknown>) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    console.warn('key length is not same');

    const keyDif: Record<string, unknown>[] = [];
    if (keys1.length > keys2.length) {
      keys1.forEach((key) => {
        if (key in object2) return;

        keyDif.push({ [key]: object1[key] });
      });

      console.warn('keyDif', keyDif);

      console.warn(ob1Title, keys1.sort(), ob2Title, keys2.sort());
      return false;
    }

    if (keys2.length > keys1.length) {
      keys2.forEach((key) => {
        if (key in object2) return;
        keyDif.push({ [key]: object2[key] });
      });

      console.warn('keyDif', keyDif);

      console.warn(ob1Title, keys1.sort(), ob2Title, keys2.sort());
      return false;
    }
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      console.info({ key, [ob1Title]: object1[key], [ob2Title]: object2[key] });
    }
  }
}

export const handleParamsToInt = (param: string | string[]) => {
  let paramVal = '';
  if (Array.isArray(param)) {
    const firstParam = param?.find((p) => p) || '0';
    paramVal = firstParam;
  } else {
    paramVal = param;
  }

  const paramInt = parseInt(paramVal);
  if (isNaN(paramInt)) return 0;
  return paramInt;
};

export function getRejectionRowClass(rowItem: PurchaseOrderUploadHistoryRejectionType) {
  if (
    rowItem.addressErrorPartialMatch ||
    rowItem.incompleteLastFourOfSocial ||
    rowItem.providerNotInDatabase ||
    rowItem.renewalProviderChanged ||
    rowItem.renewalSiteChanged ||
    rowItem.missingLocationIEN
  ) {
    return '!bg-yellow-200';
  }
  return '!bg-red-200';
}

export function formatRejectionType(rowItem: PurchaseOrderUploadHistoryRejectionType) {
  if (rowItem.tooSoonToRenew) {
    return 'Too soon to renew';
  } else if (rowItem.missingPatientICN) {
    return 'Missing Patient ICN';
  } else if (rowItem.patientMissingDFN) {
    return 'Missing PatientIEN (DFN)';
  } else if (rowItem.missingSerialNumber) {
    return 'Missing DS#';
  } else if (rowItem.serialNumberAlreadyInUse) {
    return 'Duplicate DS#';
  } else if (rowItem.incompleteLastFourOfSocial) {
    return 'Incomplete Last Four';
  } else if (rowItem.missingPhoneNumbers) {
    return 'Missing Phone Number';
  } else if (rowItem.missingNameParts) {
    return 'Missing Full Name';
  } else if (rowItem.renewalSiteChanged) {
    return 'Renewal site does match current site';
  } else if (rowItem.renewalProviderChanged) {
    return 'Prescribing provider does not match current provider';
  } else if (rowItem.missingEnrollmentDate) {
    return 'Missing Enrollment Date';
  } else if (rowItem.missingUpdatedOrderDate) {
    return 'Missing Updated Order Date';
  } else if (rowItem.missingStationOrSiteInfo) {
    return 'Missing Site';
  } else if (rowItem.addressErrorNotFound) {
    return 'Address Not Found';
  } else if (rowItem.addressErrorPartialMatch) {
    return 'Address Was Partial Match';
  } else if (rowItem.noMatchingStation) {
    return 'No Matching Station';
  } else if (rowItem.missingProviderInfo) {
    return 'Missing Provider';
  } else if (rowItem.providerNotInDatabase) {
    return 'Provider Not Found';
  } else if (rowItem.serialNumberBadFormat) {
    return 'Bad Serial Number Format';
  } else if (rowItem.phoneNumberTooLong) {
    return 'Phone number is too long';
  } else if (rowItem.patientIsWithdrawn) {
    return 'Patient is withdrawn';
  } else if (rowItem.icnAndPatientNameDontMatch) {
    return 'ICN and patient name mismatch';
  } else if (rowItem.missingLocationIEN) {
    return 'Missing LocationIEN';
  } else {
    return 'Unknown';
  }
}

export const handleSearchInput = ({
  customerId,
  searchProviderNPI,
  firstName,
  lastName,
  selectedState
}: {
  customerId: number;
  searchProviderNPI?: string;
  firstName?: string;
  lastName?: string;
  selectedState?: string;
}): ProviderLookupOptions => {
  const searchParam: ProviderLookupOptions = {
    customerId,
    registryLookupOnly: true
  };
  if (searchProviderNPI) searchParam.number = searchProviderNPI;
  if (firstName) searchParam.firstName = firstName;
  if (lastName) searchParam.lastName = lastName;
  if (selectedState) searchParam.state = selectedState;

  return searchParam;
};
